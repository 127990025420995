import React from "react"
import Navbar from "./components/Navbar"
import MyAccount from "./pages/MyAccount";
import Footer from "./components/Footer";
import {useEffect} from "react"
import axios, { Axios } from 'axios'


import Courses from "./pages/Courses";
import Main from "./pages/Main";
import Quiz from "./pages/newQuiz/Quiz";
import List from "./pages/List";
import JoinChat from "./pages/JoinChat";
import Register from "./pages/Register";
import Settings from "./pages/Settings";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import ChangeEmailSettings from "./pages/ChangeEmail";
import ChangePasswordSettings from "./pages/ChangePassword";
import RestorePassword from "./pages/RestorePassword";
import RestorePasswordRequest from "./pages/RestorePasswordRequest";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Paypal from "./pages/Paypal";
import RepeatQuiz from "./pages/newQuiz/RepeatQuiz";
import PayingQuestion from "./pages/PayingQuestion";
import PayingQuestionRest from "./pages/PayingQuestionRest";
import PayedInfo from "./pages/PayedInfo";
import PayedInfo2 from "./pages/PayedInfo2";
import PaypalCheckout from "./pages/PaypalCheckout";
import Copyright from "./pages/Copyright";
import NotFoundPage from "./pages/NotFoundPage";

import { BrowserRouter, Routes, Route, Navigate, useLocation  } from "react-router-dom";

import { useState } from "react";

import { CodeProvider } from "./context/Context";

import { FriendNameProvider } from "./context/ContextFriendName";

const MyComponent = ({loginStatus, setLoginStatus}) => {

  const location = useLocation();
  
  const [isPaid, setIsPaid] = useState(3)

  useEffect(() => {
    //console.log("loginStatus", loginStatus)
    axios.get(`${process.env.REACT_APP_BACKEND}/login`).then((response) =>{
      setLoginStatus(response.data.loggedIn)
      //console.log(response.data.loggedIn)
      //console.log(typeof response.data.loggedIn)
    })

  }, [location.search])

  const [loggedInWithGoogle, setLoggedInWithGoogle] = useState(3)

  useEffect(() => {
    const isGoogleLoggedIn = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/isLoggedInWithGoogle`);
        if (response.data.loggedInWay === 'usualLogin') {
          setLoggedInWithGoogle(1);
        } else if (response.data.loggedInWay === 'googleLoggedIn') {
          setLoggedInWithGoogle(2);
        }
      } catch (error) {
        console.error('Error checking login status:', error);
        if (error.response) {
          console.error('Server responded with:', error.response.data);
        }
      }
    };
    isGoogleLoggedIn()
}, [location.search]);

useEffect(() => {
  const is_paid = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/is_paid`);
      //console.log(response.data.paid);
      if (response.data.paid === 1) {
        setIsPaid(response.data.paid);
      } else if (response.data.paid === 0) {
        setIsPaid(2);
      }
    } catch (error) {
      console.error("Error fetching payment status:", error);
    }
  };
  is_paid()
}, [location.search]);
  
  const authenticate = (route) => {
    //console.log("Current path:", location.pathname);
    //console.log("Login status inside authenticate:", loginStatus);
    switch(loginStatus) {
      case undefined:
        return <><div className="main"></div></>
      case false:
        return <Register/>
      case true:
        return route
    }
  }

  const authenticate2 = (route) => {
    switch(loginStatus) {
      case undefined:
        return <><div className="main"></div></>
      case false:
        return <Register/>
      case true:
        return <Main/>
    }
  }

  const authenticate3 = (route) => {
    switch(loginStatus) {
      case undefined:
        return <><div className="main"></div></>
      case false:
        return <Register/>
      case true:
        if (isPaid === 2){       
          return <Navigate to="/payingPage" replace />;
        }else{
          return route
        }
    }
  }

  const authenticate4 = (route) => {

    switch(loginStatus) {
      case undefined:
        return <><div className="main"></div></>
      case false:
        return <Register/>
      case true:
        if (isPaid === 2){
          return route
        }else if(isPaid === 1){
          return <Navigate to="/payed" replace />;
        }
    }
  }

  const authenticate5 = (route) => {

    switch(loginStatus) {
      case undefined:
        return <><div className="main"></div></>
      case false:
        return <Register/>
      case true:
        if (loggedInWithGoogle === 2){
          return <Navigate to="loggedInWithGoogle" replace />;
        }else{
          return route
        }
    }
  }

  const authenticate6 = (route) => {

    switch(loginStatus) {
      case undefined:
        return <><div className="main"></div></>
      case false:
        return <Register/>
      case true:
        if (isPaid === 2){
          return route
        }else if(isPaid === 1){
          return <Navigate to="/payedInfo" replace />;
        }
    }
  }

  return (
    <div className="totalContainer">
      <Navbar/>
      <Routes>
        <Route path="/register" element={authenticate2(<Register/>)} />
        <Route path="/" element={authenticate(<Main/>)} />
        <Route path="/courses"  element={authenticate(<Courses/>)} />
        <Route path="/account" element={authenticate(<MyAccount/>)} />
        <Route path="/joinchat" element={authenticate3(<JoinChat/>)} />
        <Route path="/quiz" element={authenticate(<Quiz/>)} />
        <Route path="/list" element={authenticate(<List/>)} />
        <Route path="/settings" element={authenticate(<Settings/>)} />
        <Route path="/changeEmail" element={authenticate5(<ChangeEmailSettings/>)} />
        <Route path="/changePassword" element={authenticate5(<ChangePasswordSettings/>)} />
        <Route path="/verifyemail" element={<VerifyEmailPage/>}/>
        <Route path="/restorepassword" element={<RestorePassword/>}/>
        <Route path="/restorepasswordrequest" element={<RestorePasswordRequest/>}/>             
        <Route path="/about" element={<About/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/paypal" element={authenticate(<Paypal/>)} />
        <Route path="/repeatQuiz" element={authenticate(<RepeatQuiz/>)} />
        <Route path="/paying" element={authenticate6(<PayingQuestion/>)} />
        <Route path="/payingPage" element={authenticate6(<PayingQuestionRest/>)} />
        <Route path="/paypalPayment" element={authenticate4(<PaypalCheckout/>)} />
        <Route path="/payed" element={authenticate(<PayedInfo/>)} />
        <Route path="/payedInfo" element={authenticate(<PayedInfo2/>)} />
        <Route path="/copyrightInfo" element={authenticate(<Copyright/>)} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer/>
    </div>
  )
}

export default function App() {

  const[loginStatus, setLoginStatus] = useState(undefined)
  
    return (
          <div>
            <FriendNameProvider>
              <CodeProvider>
                <BrowserRouter>
                  <MyComponent loginStatus={loginStatus} setLoginStatus={setLoginStatus}/>
                </BrowserRouter>
                </CodeProvider>
                </FriendNameProvider>
          </div>
    )
}